<template>
  <div class="wrapper__project-show" v-if="project">
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <VueScrollFixedNavbar>
          <v-card>
            <v-card-text>
              <div class="project-show-title">
                <p>{{ t("Title Project") }}:</p>
                <h3>
                  <strong>{{ projectLocale.name }}</strong>
                </h3>
              </div>

              <div class="d-flex justify-space-between">
                <div class="project-show-title-status">
                  <p>
                    {{ t("Status Project") }}:
                    <project-status :status="project.status" />
                  </p>
                </div>
                <div class="project-show-title-actions">
                  <div class="project-versions d-flex justify-end">
                    <v-btn
                      color="success"
                      text
                      @click="onSaveStepsForm"
                      :disabled="isSaveStepsForm"
                    >
                      <v-icon dark left>
                        {{ icons.mdiContentSaveAlertOutline }}
                      </v-icon>
                      {{ t("SaveProject") }}
                    </v-btn>
                    <project-add-permission :project="project" />

                    <a
                      rel="noopener noreferrer"
                      v-for="(version, index) in proyectVersions"
                      @click="onChangeProjectVersion(version.language)"
                      :key="index"
                    >
                      <div
                        class="filter-locale-flag"
                        :class="{ active: locale === version.language }"
                      >
                        <flag-locale
                          :locale="version.language"
                          width="35px"
                          height="35px"
                          :id="locale"
                          circle
                        />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </VueScrollFixedNavbar>
      </v-col>
    </v-row>
    <div class="project-form">
      <div class="d-flex justify-space-between align-center">
        <h3 class="mt-6 mb-6 text-uppercase">{{ t("Data Project") }}</h3>
        <div class="">
          <button-download downloadEs downloadFr :project="project" />
        </div>
      </div>

      <v-card>
        <v-card-text>
          <KeepAlive>
            <component
              :is="resolveDefaultCustomForm"
              :version="activeProyectVersion"
              :step="activeStepCustomForm"
              :project="project"
              :isSaveStepsForm="isSaveStepsForm"
              @onChangeVersion="onChangeVersionForm"
              @onFinishForm="onFinishForm"
              @onStepActive="onStepActiveCustomForm"
            />
          </KeepAlive>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from "@vue/composition-api";
import api from "@/services";
import CardSection from "@/components/general/CardSection";
import FlagLocale from "@/components/general/FlagLocale";
import ProjectStatus from "@/components/projects/Status";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";
import { getI18nLocale } from "@/plugins/i18n";
import router from "@/router";
import ProjectAddPermission from "@/components/projects/ProjectAddPermission";
import ButtonDownload from "@/components/projects/download/ButtonDownload";
import {FormAnnouncementInput} from "@/components/announcenments";

import { VueScrollFixedNavbar } from "vue-scroll-fixed-navbar";
import { mdiContentSaveAlertOutline } from "@mdi/js";

export default {
  components: {
    CardSection,
    FlagLocale,
    ProjectStatus,
    ProjectAddPermission,
    VueScrollFixedNavbar,
    ButtonDownload,
    ...FormAnnouncementInput
  },
  setup() {
    const { t } = useUtilsI18n();
    const localei18n = computed(() => getI18nLocale());
    const locale = ref(localei18n.value);
    const filterLocale = ref(localei18n.value);
    const activeStepCustomForm = ref(1);

    const project = ref(null);
    const projectLocale = computed(() => project.value[filterLocale.value]);
    const proyectVersions = computed(() => project.value?.versions || []);
    const activeProyectVersion = computed(() =>
      proyectVersions.value.find((v) => v.language === filterLocale.value)
    );

    const isSaveStepsForm = ref(false);

    const onSaveStepsForm = () => {
      isSaveStepsForm.value = true;
    };

    const resolveDefaultCustomForm = computed(() => {
      const { form_folder_key } = project.value

      if(!form_folder_key) return;

      return `${form_folder_key}-${filterLocale.value}`;
    });

    const onChangeProjectVersion = (l) => {
      locale.value = l;
    };

    const onChangeVersionForm = ({ version }) => {
      const otherVersion = project.value.versions.find(
        (v) => version.language !== v.language
      );
      project.value.versions = [version, otherVersion];

      isSaveStepsForm.value = false;
    };

    const onFinishForm = () => {};

    const onStepActiveCustomForm = ({ step }) => {
      activeStepCustomForm.value = step;
    };

    const getProjectById = async ({ projectId }) => {
      try {
        const { data: response } = await api.getProjectById(projectId);
        const { data } = response;
        project.value = data;
      } catch (error) {}
    };

    watch(localei18n, (l) => (filterLocale.value = l));
    watch(locale, (l) => (filterLocale.value = l));

    onMounted(() => {
      const { id } = router.currentRoute.params;

      if (id) getProjectById({ projectId: id });
    });

    return {
      locale,
      localei18n,
      filterLocale,
      project,
      projectLocale,
      proyectVersions,
      activeProyectVersion,
      resolveDefaultCustomForm,
      activeStepCustomForm,
      isSaveStepsForm,

      icons: {
        mdiContentSaveAlertOutline,
      },

      getProjectById,
      onChangeProjectVersion,
      onChangeVersionForm,
      onFinishForm,
      onStepActiveCustomForm,
      onSaveStepsForm,

      t,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper__project-show {
  .project-show-title {
    display: flex;
    gap: 1rem;
  }
  .project-versions {
    display: flex;
    gap: 1rem;

    .filter-locale-flag {
      filter: grayscale(1);
      &.active {
        filter: grayscale(0);
      }
    }
  }
}
</style>
