<template>
  <div class="wrapper__project-add-permission">
    <v-btn color="success" rounded dark @click="onDrawerToogle(true)" v-if="projectIsSharedSuccessfully">
      <v-icon dark left>
        {{ icons.mdiCheckCircleOutline }}
      </v-icon>
      {{ t("Project shared") }}
    </v-btn>

    <v-btn color="error" text @click="onDrawerToogle(true)" v-else>
      <v-icon dark left>
        {{ icons.mdiCogOutline }}
      </v-icon>
      {{ t("Permissions Project") }}
    </v-btn>

    <WrapperDrawer :title="t('Permissions Project')" v-model="drawerToogle">
      <!-- Steps -->
      <v-row>
        <v-col cols="12">
          <v-alert v-if="stepOne" border="left" colored-border text color="info">
            <div v-html="t('SharedProject.STEP_ONE')"></div>
          </v-alert>
          <v-alert v-if="stepTwo" border="left" colored-border text color="info">
            <div v-html="t('SharedProject.STEP_TWO')"></div>
          </v-alert>          
          <v-alert v-if="projectIsSharedSuccessfully" border="left" colored-border text color="success">
            <div v-html="t('SharedProject.PROJECT_SHARED_SUCCESSFULLY')">
            </div>
          </v-alert>
        </v-col>
      </v-row>

      <v-divider class="my-6"></v-divider>

      <!-- Form -->
      <v-row v-if="stepOne">
        <v-col cols="12">
          <v-form ref="permissionForm" @submit.prevent="handleFormSubmit">
            <v-row>
              <v-col cols="12" xs="12" md="12">
                <FormTextField
                  :label="t('Mail user')"
                  v-model="permissionFormData.email"
                  :rules="[validators.emailValidator, validators.required]"
                />
              </v-col>

            </v-row>

            <v-btn color="primary" type="submit" class="float-right mt-5 mb-5" :loading="loading">
              {{ t("Permissions Give") }}
            </v-btn>
          </v-form>
        </v-col>
      </v-row>

      <!-- Managers -->
      <div class="">
        <p><strong>{{ $t('Project managers') }}</strong></p>

        <div class="d-flex">          
          <div class="flag-country">
            <flag-locale
              :locale="project.owner.country_code"
              width="35px"
              height="35px"
              circle
              class="d-inline-block"
            />
          </div>
          <p class="pl-4">
            <span class="d-block">{{ project.owner.fullname }}</span>
            <span><small>{{ project.owner.email }}</small></span>
          </p>
          <p class="px-6"><v-chip small color="success">{{ $t('Creator') }}</v-chip></p>
        </div>

        <div class="" v-if="!projectManagerRoleDirectors.length && !projectInvites.length">
          <p><strong>{{ $t('Not assign') }}</strong></p>
        </div>

        <div class="d-flex" v-for="(user, index) in projectManagerRoleDirectors" :key="index">          
          <div class="flag-country">
            <flag-locale
              :locale="user.country_code"
              width="35px"
              height="35px"
              circle
              class="d-inline-block"
            />
          </div>
          <p class="pl-4">
            <span class="d-block">{{ user.fullname }}</span>
            <span><small>{{ user.email }}</small></span>
          </p>
          <p class="px-6"><v-chip small>{{ $t('Shared') }}</v-chip></p>
        </div>

        <div 
          class="d-flex user-pending-invite" 
          v-for="({ id: inviteId, country_code, fullname, email }, index) in projectInvites" :key="index">          
          <div class="flag-country">
            <flag-locale
              :locale="country_code"
              width="35px"
              height="35px"
              circle
              class="d-inline-block"
            />
          </div>
          <p class="pl-4">
            <span class="d-block">{{ fullname }}</span>
            <span><small>{{ email }}</small></span>

            <a @click.stop="canceleProyectInvite(inviteId)" class="d-block mt-4" :disabled="loading">{{ $t('Cancele invitation') }}</a>
          </p>
          <p class="px-6"><v-chip small color="warning">{{ $t('SharedProject.SOLICITUDE_PENDING') }}</v-chip></p>
        </div>

      </div>
    </WrapperDrawer>
  </div>
</template>

<script>
import WrapperDrawer from "@/components/general/WrapperDrawer";
import FormTextField from "@/components/forms/TextField";
import FlagLocale from "@/components/general/FlagLocale";
import {
  ref,
  reactive,
  computed,
  getCurrentInstance,
  onUpdated,
} from "@vue/composition-api";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";
import { mdiCogOutline, mdiCheckCircleOutline } from "@mdi/js";
import { emailValidator, required } from "@core/utils/validation";
import { ROLE_DIRECTOR } from "@/services/roles";
import api from "@/services";

export default {
  components: {
    WrapperDrawer,
    FormTextField,
    FlagLocale
  },
  props: {
    project: {
      type: Object,
      default: () => null,
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy;
    const { project } = props;
    const getProject = ref(project);
    const loading = ref(false);

    const { t } = useUtilsI18n();
    const drawerToogle = ref(false);

    const permissionFormData = reactive({
      email: null,
      role_name: ROLE_DIRECTOR,
    });

    const projectManagers = computed(() => getProject.value.managers || []);
    const projectId = computed(() => getProject.value?.id);
    const projectManagerRoleDirectors = computed(() => projectManagers.value?.filter(m => m.role_name === ROLE_DIRECTOR));
    const projectIsSharedSuccessfully = computed(() => getProject.value.is_shared_successfully);
    const projectInvites = computed(() => getProject.value.invites);
    
    const stepOne = computed(() => !projectInvites.value.length && !projectIsSharedSuccessfully.value)
    const stepTwo = computed(() => projectInvites.value.length)

    const onDrawerToogle = (value) => {
      drawerToogle.value = value;
    };

    const canceleProyectInvite = async (invite_id) => {
      try {
        loading.value = true;
        
        const { data: response } = await api.canceleProyectInvite({ invite_id });
        const { data } = response;
        getProject.value = data;

      } catch (error) {
      } finally {
        loading.value = false;
      }
    }

    const handleFormSubmit = async () => {
      const permissionForm = vm.$refs.permissionForm;
      if (!permissionForm.validate()) return;
      try {
        loading.value = true;
        const { email } = permissionFormData;
        
        const { data: response } = await api.userInviteProjects(
          projectId.value,
          permissionFormData
        );
        const { data } = response;
        getProject.value = data;

        vm.$alert(null, null, "success", {
          html: `${vm.$t(
            "SharedProject.SUCCESS"
          )} <br/> <strong>${email}</strong>`,
        });

        permissionForm.reset();
      } catch (error) {
        const { data = {} } = error.response;
        const { codeError = "ERROR" } = data;

        vm.$alert(null, null, "error", {
          html: vm.$t(`SharedProject['${codeError}']`),
        });
      } finally {
        loading.value = false;
      }
    };

    onUpdated(() => {});

    return {
      drawerToogle,
      getProject,
      projectManagers,
      projectManagerRoleDirectors,
      projectIsSharedSuccessfully,
      projectInvites,
      permissionFormData,
      loading,

      stepOne,
      stepTwo,

      icons: {
        mdiCogOutline,
        mdiCheckCircleOutline,
      },

      validators: {
        emailValidator,
        required
      },

      onDrawerToogle,
      handleFormSubmit,
      canceleProyectInvite,

      t,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper__project-add-permission {
  .wrapper__form-access-permissions {
    margin-top: 15rem;
  }
}

.user-pending-invite {
  color: var(--v-warning-base);
  font-weight: bold;
}
</style>
